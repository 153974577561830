<template>
  <v-app id="inspire">
    <v-container
      fluid
      fill-height
      id="image"
      v-if="!smallScreen && !mediumScreen && !mobile"
    >
      <v-row justify="start" class="ma-5">
        <v-col align="center">
          <v-row>
            <v-col cols="6"
              ><span class="text-h1 font-weight-black">{{ error }}</span></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="6">
              <span class="text-h2 font-weight-bold">{{ message }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <span class="body-1">{{ info }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-btn class="actionButton" @click="backToSquareOne">
                Encerrar Sessão
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid fill-height v-else>
      <v-row justify="center" class="ma-5">
        <v-col align="center">
          <v-row>
            <v-col cols="12"
              ><span class="text-h1 font-weight-black">{{ error }}</span></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="text-h2 font-weight-bold">{{ message }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="body-1">{{ info }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn class="actionButton" @click="backToSquareOne">
                Encerrar Sessão
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
export default {
  name: "notFound",
  data() {
    return {
      error: "403",
      message: "ACESSO NEGADO!",
      info: "Por algum motivo sua conta foi desativada. Caso ache que isso é um erro, contate nosso suporte ou entre com outra conta.",
    };
  },
  methods: {
    async backToSquareOne() {
      await this.$firebase.auth().signOut();
      this.$store.commit("CLEAR_USER");
      this.$store.commit("CLEAR_TOKEN");
      this.$store.commit("CLEAR_ACCESS");
      this.$router.push("/login");
    },
  },
  computed: {
    mediumScreen() {
      return this.$vuetify.breakpoint.md;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    smallScreen() {
      return this.$vuetify.breakpoint.sm;
    },
  },
};
</script>
<style scoped>
#image {
  background: url("../../../../assets/img/portal.png");
  background-position: right;
  background-repeat: no-repeat;
}
</style>
